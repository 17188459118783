import React, { useState, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Navbar, Nav, NavDropdown, Image } from "react-bootstrap";
import { SideNav } from "./SideNav";
import Dashboard from "../../screens/DesktopLayout/main/Dashboard";
import Properties from "../../screens/DesktopLayout/Properties";
import UserManagement from "../../screens/DesktopLayout/main/UserManagement";
import Settings from "../../screens/DesktopLayout/Settings";
import Billing from "../../screens/DesktopLayout/Billing";
import Booking from "../../screens/DesktopLayout/Booking";
import EditBooking from "../../screens/DesktopLayout/EditBooking";
import StartBooking from "../../screens/DesktopLayout/StartBooking";
import AddedProperties from "../../screens/DesktopLayout/AddedProperties";
import Checkout from "../Settings/Checkout";
import Cart from "../../screens/DesktopLayout/cart";
import FilterProperties from "../../screens/DesktopLayout/FilterProperties";
import NewStartBooking from "../../screens/DesktopLayout/NewStartBooking";
import ListProperty from "../../screens/DesktopLayout/ListProperty";
import ListPropertyForm from "../../screens/DesktopLayout/ListPropertyForm";
import SERVICES from "../../services";
import {
	resetTotalAmount,
	saveCartDetails,
	saveFilterOptions, setCartEntries,
} from "../../redux/actions/dashboard";
import { Constants } from '../../utils/constants';
import CompanyApprovals from "../../screens/DesktopLayout/main/hosts/CompanyApprovals";
import ApprovedReservations from "../../screens/DesktopLayout/main/hosts/ApprovedReservations";
import MakePayment from "../../screens/DesktopLayout/make-payment";
import EditProperty from "../../screens/DesktopLayout/EditProperty";
import PaymentSuccess from "../../screens/DesktopLayout/make-payment/payment-success";
import HostBilling from "../../screens/DesktopLayout/HostBilling";
import MembershipPlan from "../../screens/DesktopLayout/bundles/membership-plan";
import HomeDashboard from "../../screens/DesktopLayout/main/owner/dashboard/home";
import Orders from "../../screens/DesktopLayout/admin/orders";
import Packages from "../../screens/DesktopLayout/admin/packages";
import Announcements from "../../screens/DesktopLayout/admin/announcements";

const AppLayout = () => {
	const [cart, setCart] = useState(false);
	const [currentUser, setCurrentUser] = useState({});
	const [currentUserRole, setCurrentUserRole] = useState('');
	const [bundleType, setBundleType] = useState('');
	const [packageUsageStats, setPackageUsageStats] = useState([]);

	const dispatch = useDispatch();
	const allEntries = useSelector((state) => state.dashboard.entries);
	const hasItems = useSelector((state) => state.dashboard.hasItems);
	const history = useHistory();
	const filterOptionsUrl =
		`${Constants.BASE_URL}/booking/api/v1/listings/filter/options`;

	const storageKeys = ['companyId', 'currentUser', 'persist:root', 'userCurrency'];

	useEffect(() => {
		setCart(hasItems);

		const currentUser = SERVICES.getUser();

		if (!currentUser) history.push("/signin");
		setCurrentUser(currentUser);


		axios.get(filterOptionsUrl).then((res) => {
			const resData = res.data ? res.data.data : {};
			dispatch(saveFilterOptions(resData));
		});
	}, [hasItems, dispatch, history]);

	useEffect(() => {
		const role = currentUser.role;

		setCurrentUserRole(role);

		const packagePlan = currentUser?.packagePlan;
		const bundle = packagePlan?.bundle;
		const bundleType = bundle?.bundleType;
		const bundleTypeLabel = bundleType ? bundleType.label : "";

		setBundleType(bundleTypeLabel);

		const packageUsages = packagePlan?.packageUsages;
		if (packageUsages && packageUsages.length > 0) {
			const formattedPackageUsages = packageUsages.reduce((accumulator, current) => {
				const { accessDuration, propertyType, remainingNumberOfCheckIns } = current;
				const { periodType, duration } = accessDuration;
				const { value, label } = propertyType;

				accumulator.push({
					label: value === "HOT_DESK" ? "Co-working" : label,
					duration: `${duration} ${periodType.value.toLowerCase()}${duration > 1 ? "s" : ""}`,
					checkIns: remainingNumberOfCheckIns > 0 ? remainingNumberOfCheckIns : "UNLIMITED"
				});
				return accumulator;
			}, []);

			setPackageUsageStats(formattedPackageUsages);
		}
	}, [currentUser]);

	const logout = () => {
		const currentUser = SERVICES.getUser();
		const userId = currentUser ? currentUser.id : 0;

		const storedListingData = SERVICES.getListingData();
		const listingOwnerId = storedListingData ? storedListingData.ownerId : 0;

		if (userId && listingOwnerId && (userId === listingOwnerId)) {
			// if current user has unfinished listings, remove items from storage leaving only owner's listing specific data
			storageKeys.forEach(key => localStorage.removeItem(key));
		} else {
			localStorage.clear();
		}

		history.push("/signin");

		// reset cart entries
		dispatch(saveCartDetails([]));
		dispatch(setCartEntries());
		dispatch(resetTotalAmount());
	};

	return (
		<Container className="min-vh-100" style={{ backgroundColor: "#F9F9F9", paddingLeft: 0, paddingRight: 0 }} fluid>
			<Row>
				<Col md="2" className="p-0">
					<SideNav currentUserRole={currentUser.role} />
				</Col>
				<Col>
					<Navbar>
						<Container fluid>
							<Navbar.Toggle aria-controls="basic-navbar-nav" />
							<Navbar.Collapse id="basic-navbar-nav">
								<Nav className="ml-auto align-items-center">
									{/*<Nav.Link href="#" className="top-nav-item"><Image src="../../assets/img/Web/Spacia/settings/navbar/bell 1.png" alt="bell" fluid /></Nav.Link>*/}
									{
										currentUserRole && (currentUserRole !== 'ROLE_ADMINISTRATOR'
											&& currentUserRole !== 'ROLE_HOST' && currentUserRole !== 'ROLE_HOST_OPERATIONS')
											? (
												<div className={'d-flex me-3'}>
													<p>Number of Check-ins Available:  &nbsp;</p>
													<NavDropdown
														alignRight="false"
														className="top-nav-item text-primary plan-details"
														style={{ textDecoration: 'underline', color: '#017BEF' }}
														title={"See details"} id="basic-nav-dropdown"
													>
														<NavDropdown.Item>
															Plan: &nbsp;<span
																className={'text-primary'}>{bundleType}</span>
														</NavDropdown.Item>
														{packageUsageStats.map(({ label, duration, checkIns }) => {
															return (
																<>
																	<NavDropdown.Item>
																		{label} Check-ins available: &nbsp; <span
																			className={'text-primary'}>{checkIns}</span>
																	</NavDropdown.Item>
																	<NavDropdown.Item>
																		{label} time available: &nbsp;<span
																			className={'text-primary'}>{duration}</span>
																	</NavDropdown.Item>
																</>
															);
														})}
													</NavDropdown>
												</div>
											) : null
									}
									{
										(currentUserRole !== 'ROLE_HOST' && currentUserRole !== 'ROLE_HOST_OPERATIONS') &&
										<Nav.Link href="/cart" className="mx-4 top-nav-item">{cart
											? <Image src="../../assets/img/Web/Spacia/activeCart.png" alt="bell" />
											: <Image src="../../assets/img/Web/Spacia/cart 3.png" alt="bell" />
										}</Nav.Link>
									}
									<Nav.Link className="mr-3 top-nav-item"><Image roundedCircle src={currentUser.avatar ? currentUser.avatar : 'assets/img/profileplace.png'} className="img-fluid" alt="img" style={{ width: "35px", height: "35px", objectFit: "unset" }} /></Nav.Link>
									<NavDropdown alignRight="false" className="top-nav-item"
										title={currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : "N/A"} id="basic-nav-dropdown">
										<NavDropdown.Item onClick={() => logout()}>Logout</NavDropdown.Item>
									</NavDropdown>
								</Nav>
							</Navbar.Collapse>
						</Container>
					</Navbar>

					<Switch>
						<Route exact path="/" component={Dashboard} />
						<Route exact path="/dashboard" component={Dashboard} />
						<Route exact path='/dashboard/home' component={HomeDashboard} />
						<Route exact path="/properties" component={Properties} />
						<Route path="/properties/:sub" component={Properties} />
						<Route path="/user-management" component={UserManagement} />
						<Route path="/settings" component={Settings} />
						<Route path="/billing" component={Billing} />
						<Route path="/orders" component={Orders} />
						<Route path="/packages" component={Packages} />
						<Route path="/announcements" component={Announcements} />
						<Route path="/host-billing" component={HostBilling} />
						<Route path="/reservations" component={Booking} />
						<Route path="/editbooking" component={EditBooking} />
						<Route exact path="/editproperty/:id" component={EditProperty} />
						<Route path="/startbooking" component={StartBooking} />
						<Route path="/addedproperties" component={AddedProperties} />
						<Route path="/checkout" component={Checkout} />
						<Route path="/cart" component={Cart} />
						<Route path="/filterprops" component={FilterProperties} />
						<Route path="/listproperty" component={ListProperty} />
						<Route path="/listpropertyform" component={ListPropertyForm} />
						<Route path="/newstartBooking" component={NewStartBooking} />
						<Route path="/companyApprovals" component={CompanyApprovals} />
						<Route path="/approvedReservations" component={ApprovedReservations} />
						<Route path='/make-payment' component={MakePayment} />
						<Route path='/payment-success' component={PaymentSuccess} />
						<Route path='/membership-plan' component={MembershipPlan} />
					</Switch>
				</Col>
			</Row>
		</Container>
	);
};

export default AppLayout;
