import React, { useState, useEffect } from "react";
import {
    Container,
    Toast,
    Form,
    Button,
    Table,
} from "react-bootstrap";
import axios from "axios";
import SERVICES from '../../../../services';
import { Constants } from '../../../../utils/constants';
import { ErrorAlert } from "../../../../components/Settings/alerts/ErrorAlert";
import SuccessAlert from "../../../../components/Settings/alerts/SuccessAlert";

const Announcements = () => {
    const [announcements, setAnnouncements] = useState([]);
    const [newAnnouncement, setNewAnnouncement] = useState({ title: '', message: '' });
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        fetchAnnouncements();
    }, []);

    const fetchAnnouncements = () => {
        axios.get(`${Constants.BASE_URL}/api/announcements`)
            .then(response => {
                if (Array.isArray(response.data)) {
                    setAnnouncements(response.data);
                } else {
                    console.error("Invalid response format:", response.data);
                    setErrorMessage("Invalid data received from server");
                }
            })
            .catch(error => {
                console.error("Error fetching announcements:", error);
                setErrorMessage("Error fetching announcements. Please try again later.");
            });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewAnnouncement(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(`${Constants.BASE_URL}/api/announcements`, newAnnouncement)
            .then(response => {
                setShowSuccessAlert(true);
                setNewAnnouncement({ title: '', message: '' });
                fetchAnnouncements();
            })
            .catch(error => {
                setShowErrorAlert(true);
                setErrorMessage("An error occurred while creating the announcement");
                console.error("Error creating announcement:", error);
            });
    };

    return (
        <div>
            <SuccessAlert show={showSuccessAlert} message="Announcement created successfully" close={() => setShowSuccessAlert(false)} />
            <ErrorAlert show={showErrorAlert} message={errorMessage} close={() => setShowErrorAlert(false)} />
            <div>
                {/* Header */}
                <div className="header">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                        <div>
                            <h4>
                                <b>Announcements</b>
                            </h4>
                        </div>
                    </div>
                </div>
                {/* End Header */}

                <Container>
                    <Toast style={{ width: "100%", padding: 20, marginBottom: 50, maxWidth: '100%' }}>
                        <h6 style={{ fontWeight: "bold", marginBottom: 20 }}>
                            Create New Announcement
                        </h6>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <Form.Label>Title</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="title"
                                    value={newAnnouncement.title} 
                                    onChange={handleInputChange}
                                    placeholder="Enter announcement title"
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Message</Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    rows={3}
                                    name="message"
                                    value={newAnnouncement.message} 
                                    onChange={handleInputChange}
                                    placeholder="Enter announcement message"
                                    required
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Send Announcement
                            </Button>
                        </Form>
                    </Toast>

                    <Toast style={{ width: "100%", padding: 20, marginBottom: 50, maxWidth: '100%' }}>
                        <h6 style={{ fontWeight: "bold", marginBottom: 20 }}>
                            Recent Announcements
                        </h6>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Message</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {announcements.map(announcement => (
                                    <tr key={announcement.id}>
                                        <td>{announcement.title}</td>
                                        <td>{announcement.message}</td>
                                        <td>{new Date(announcement.createdAt).toLocaleString()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Toast>
                </Container>
            </div>
        </div>
    );
};

export default Announcements;
